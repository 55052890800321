<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario},{'font-size': '20px'}]"><b>PRODUCTO</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.producto_id" v-on:input="input_producto(form_producto.producto_id)"  :rules="[rules.requerido]" :items="items.productos" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE PRODUCTO *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.productodigital_id" :rules="[rules.requerido]" :items="items.tiposi" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="¿ES UN PRODUCTO DIGITAL? *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="form_producto.numero" :rules="[rules.requerido, rules.numerico]" :color="colores.primario" label="No. DE PRODUCTO *" type="number" min="0" max="999999999999999"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.pais_id" v-on:input="input_paises(form_producto.pais_id)" :items="items.paises" :loading="loadingData" item-text="descripcion" item-value="id" :rules="[rules.requerido]" :color="colores.primario" label="PAÍS *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.departamento_id" v-on:input="input_departamentos(form_producto.departamento_id)" :items="items.departamentos" :loading="loadingDepartamento" item-text="descripcion" item-value="id" :rules="[rules.requerido]" :color="colores.primario" label="DEPARTAMENTO *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="form_producto.municipio_id" :items="items.ciudades" :loading="loadingCiudad" item-text="descripcion" item-value="id" :rules="[rules.requerido]" :color="colores.primario" label="MUNICIPIO *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showPlaca">
          <v-col cols="12" md="4">
            <v-text-field v-model="form_vehiculo.placa" maxlength="10" counter="10" :rules="[rules.requerido]" :color="colores.primario" label="PLACA DEL VEHÍCULO *"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>

</div>
</template>

<script>
import recDepartamentos from '@/js/rec_departamentos.js'
import recPaises from '@/js/rec_paises.js'
export default {
  name: 'productoComponent',
  components: {

  },
  mixins: [recPaises, recDepartamentos],
  props: ['colores', 'items', 'loadingData', 'rules', 'form_producto', 'form_vehiculo'],
  data: () => ({
    showPlaca: false,
    loadingDepartamento: false,
    items_departamentos: [],
    loadingCiudad: false,
    items_ciudades: []
  }),
  created () {
    if (this.form_vehiculo.placa !== '') {
      this.showPlaca = true
    }
    this.rules = this.$store.getters.getRules
  },
  methods: {
    input_producto (pProductoId) {
      this.showPlaca = false
      if (pProductoId === 5 || pProductoId === 7 || pProductoId === 8 || pProductoId === 6) {
        this.showPlaca = true
      } else {
        this.form_producto.placa = ''
      }
    },
    input_paises (pPaisId) {
      this.rec_paises_id(pPaisId)
    },
    input_departamentos (pDepartamentoId) {
      this.rec_departamento_id(pDepartamentoId)
    }
  }
}
</script>
